// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/build/repo/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-book-entry-js": () => import("/opt/build/repo/src/templates/book-entry.js" /* webpackChunkName: "component---src-templates-book-entry-js" */),
  "component---src-templates-submissions-page-js": () => import("/opt/build/repo/src/templates/submissions-page.js" /* webpackChunkName: "component---src-templates-submissions-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-index-js": () => import("/opt/build/repo/src/pages/books/index.js" /* webpackChunkName: "component---src-pages-books-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-privacy-index-js": () => import("/opt/build/repo/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */)
}

